<template>
    <div class="card-tail min-h-[400px]">
        <div v-if="selectedAccrualId">
            <p class="text-lg font-medium mb-3 border-b">Detaljer</p>
            <div class="ml-2 mb-5">
                <div class="flex">
                    <p class="w-32 flex-shrink-0">Typ:</p>
                    <p>{{ accrualDetails.sourceType }}</p>
                </div>
                <div class="flex">
                    <p class="w-32 flex-shrink-0">Beskrivning:</p>
                    <p>{{ accrualDetails.description }}</p>
                </div>
            </div>
            <table class="w-full mb-10">
                <tr>
                    <th class="font-semibold px-2 pb-1 text-sm text-gray-600">Startdatum</th>
                    <th class="font-semibold px-2 pb-1 text-sm text-gray-600">Slutdatum</th>
                    <th class="font-semibold px-2 pb-1 text-sm text-gray-600">Bokningskonto</th>
                    <th class="font-semibold px-2 pb-1 text-sm text-gray-600" align="right">Totalbelopp</th>
                    <th class="font-semibold px-2 pb-1 text-sm text-gray-600" align="right">Restbelopp</th>
                </tr>
                <tr>
                    <td class="border px-2 py-1 text-xs">{{ formatDate(accrualDetails.startDate) }}</td>
                    <td class="border px-2 py-1 text-xs">{{ formatDate(accrualDetails.endDate) }}</td>
                    <td class="border px-2 py-1 text-xs">{{ accrualDetails.bookingAccount }}</td>
                    <td class="border px-2 py-1 text-xs" align="right">{{ accrualDetails.originalAmount | swedishNumberFormat }}</td>
                    <td class="border px-2 py-1 text-xs" align="right">{{ accrualDetails.restAmount | swedishNumberFormat }}</td>
                </tr>
            </table>

            <p class="text-lg font-medium mt-10 mb-3 border-b">Grund verifikat</p>
            <table class="w-full">
                <tr>
                    <th class="font-semibold px-2 pb-1 text-sm text-gray-600">Serie</th>
                    <th class="font-semibold px-2 pb-1 text-sm text-gray-600">Voucher nr</th>
                    <th class="font-semibold px-2 pb-1 text-sm text-gray-600">Bokningsdatum</th>
                    <th class="font-semibold px-2 pb-1 text-sm text-gray-600">Bokningskonto</th>
                    <th class="font-semibold px-2 pb-1 text-sm text-gray-600" align="right">Belopp</th>
                </tr>
                <tr v-for="item in accrualDetails.transactions.filter(t => t.serie != 'PE')" :key="item.transactionId">
                    <td class="border px-2 py-1 text-xs">{{ item.serie }}</td>
                    <td class="border px-2 py-1 text-xs">{{ item.voucherNumber }}</td>
                    <td class="border px-2 py-1 text-xs">{{ formatDate(item.bookingDate) }}</td>
                    <td class="border px-2 py-1 text-xs">{{ item.bookingAccount }}</td>
                    <td class="border px-2 py-1 text-xs" align="right">{{ item.amount | swedishNumberFormat }}</td>
                </tr>
            </table>

            <p class="text-lg font-medium mt-10 mb-3 border-b">Transaktioner</p>
            <table class="w-full">
                <tr>
                    <th class="font-semibold px-2 pb-1 text-sm text-gray-600">Serie</th>
                    <th class="font-semibold px-2 pb-1 text-sm text-gray-600">Voucher nr</th>
                    <th class="font-semibold px-2 pb-1 text-sm text-gray-600">Bokningsdatum</th>
                    <th class="font-semibold px-2 pb-1 text-sm text-gray-600">Bokningskonto</th>
                    <th class="font-semibold px-2 pb-1 text-sm text-gray-600" align="right">Belopp</th>
                </tr>
                <tr v-for="item in accrualDetails.transactions.filter(t => t.serie == 'PE')" :key="item.transactionId">
                    <td class="border px-2 py-1 text-xs">{{ item.serie }}</td>
                    <td class="border px-2 py-1 text-xs">{{ item.voucherNumber }}</td>
                    <td class="border px-2 py-1 text-xs">{{ formatDate(item.bookingDate) }}</td>
                    <td class="border px-2 py-1 text-xs">{{ item.bookingAccount }}</td>
                    <td class="border px-2 py-1 text-xs" align="right">{{ item.amount | swedishNumberFormat }}</td>
                </tr>
            </table>

            <p class="text-lg font-medium mt-10 mb-3 border-b">Kommande Transaktioner</p>
            <table class="w-full">
                <tr>
                    <th class="font-semibold px-2 pb-1 text-sm text-gray-600">Bokningsdatum</th>
                    <th class="font-semibold px-2 pb-1 text-sm text-gray-600">Bokningskonto</th>
                    <th class="font-semibold px-2 pb-1 text-sm text-gray-600" align="right">Belopp</th>
                </tr>
                <tr v-for="(item, index) in futurePayments" :key="index">
                    <td class="border px-2 py-1 text-xs">{{ formatDate(item.bookingDate) }}</td>
                    <td class="border px-2 py-1 text-xs">{{ item.bookingAccount }}</td>
                    <td class="border px-2 py-1 text-xs" align="right">{{ item.amount | swedishNumberFormat }}</td>
                </tr>
            </table>
        </div>
        <div v-else class="flex flex-col items-center py-10">
            <img src="@/assets/images/no_data.svg" class="w-32" />
            <p class="mt-4">Ingen data har valts</p>
        </div>
        <el-button class="mt-4" v-if="selectedAccrualId" size="mini" type="danger" @click="cancelAccrual" :loading="abortButtonLoading" :disabled="accrualDetails.status != 'Ongoing'">
            Avbryt periodisering
        </el-button>
    </div>
</template>
<script>
import moment from "moment";
import Accruals from "@/utility/accruals";
import Api from "./lopande_accrual.api";
import { mapState } from "vuex";

export default {
    props: {
        clientId: {
            type: String,
            default: null,
        },
        selectedAccrualId: {
            type: Number,
            default: null,
        },
    },

    data() {
        return {
            accrualDetails: {
                transactions: [],
            },
            futurePayments: [],
            abortButtonLoading: false,
        };
    },

    watch: {
        selectedAccrualId(accrualId) {
            if (!accrualId) return;
            this.getDetails();
        },
    },

    methods: {
        async cancelAccrual() {
            this.abortButtonLoading = true;
            const res = await Api.cancelAccrual(this.clientId, this.selectedAccrualId);
            if (res.status == 200) {
                this.$toasted.show("Periodisering återförd och avbryten", {
                    theme: "bubble",
                    duration: 4000,
                    position: "bottom-center",
                    type: "success",
                    keepOnHover: true,
                });
            }
            this.abortButtonLoading = false;
        },

        async getDetails() {
            this.accrualDetails = await Api.getDetails(this.clientId, this.selectedAccrualId);
            this.futurePayments = this.getFuturePayments();
        },

        getFuturePayments() {
            var payments = [];

            var firstTransaction = this.accrualDetails.transactions.filter(t => t.serie != "PE")[0];
            var bookingDate = moment(firstTransaction.bookingDate);

            var startDate = moment(this.accrualDetails.startDate);
            var endDate = moment(this.accrualDetails.endDate);

            var lastTransaction = this.accrualDetails.transactions[this.accrualDetails.transactions.length - 1];
            var lastBooking = moment(lastTransaction.bookingDate);

            let restAmount = this.accrualDetails.originalAmount;
            var amount = this.accrualDetails.originalAmount;

            let debtAccount = this.accountPlanItems.find(i => i.value === this.accrualDetails.bookingAccount).debtAccount;
            let assetAccount = this.accountPlanItems.find(i => i.value === this.accrualDetails.bookingAccount).assetAccount;

            for (let date = startDate.clone(); date <= endDate; date = date.add(1, "month").startOf("month")) {
                const interimType = (date.month() < bookingDate.month() && date.year() == bookingDate.year()) || date.year() < bookingDate.year() ? "Debt" : "Asset";
                if (date.month() === bookingDate.month() && date.year() === bookingDate.year() && bookingDate.date() !== 1) {
                    const paymentDebt = Accruals.calculatePayment(startDate, endDate, date, bookingDate, amount, restAmount, "Debt");
                    restAmount -= paymentDebt;
                    const paymentAsset = Accruals.calculatePayment(startDate, endDate, date, bookingDate, amount, restAmount, "Asset");
                    restAmount -= paymentAsset;

                    if (date > lastBooking || lastBooking.month() == bookingDate.month()) {
                        payments.push({
                            bookingDate: date.clone(),
                            bookingAccount: debtAccount,
                            amount: paymentDebt,
                        });
                        payments.push({
                            bookingDate: date.clone(),
                            bookingAccount: assetAccount,
                            amount: paymentAsset,
                        });
                    }
                } else if (interimType === "Debt") {
                    const payment = Accruals.calculatePayment(startDate, endDate, date, bookingDate, amount, restAmount, interimType);
                    if (date > lastBooking) {
                        payments.push({
                            bookingDate: date.clone(),
                            bookingAccount: debtAccount,
                            amount: payment,
                        });
                    }
                    restAmount -= payment;
                } else {
                    const payment = Accruals.calculatePayment(startDate, endDate, date, bookingDate, amount, restAmount, interimType);
                    if (date > lastBooking) {
                        payments.push({
                            bookingDate: date.clone(),
                            bookingAccount: assetAccount,
                            amount: payment,
                        });
                    }
                    restAmount -= payment;
                }
            }
            return payments;
        },

        formatDate(date) {
            return moment(date).format("YYYY-MM-DD");
        },
    },

    computed: {
        ...mapState({
            clientsInfo: state => state.clientList,
            accountsInfo: state => state.accountPlans,
        }),

        accountPlanItems() {
            var companyType = this.clientsInfo.find(c => c.value == this.clientId).companyType;
            if (companyType == 5) return this.stockCompanyAccountPlanItems;
            else return this.privateFirmAccountPlanItems;
        },

        stockCompanyAccountPlanItems() {
            var stockAccountPlans = this.accountsInfo.filter(a => a.companyType == 2)[0].accounts;
            return stockAccountPlans.map(item => {
                return {
                    name: item.name,
                    value: item.value,
                    accruable: item.accruable,
                    assetAccount: item.assetAccount,
                    debtAccount: item.debtAccount,
                };
            });
        },

        privateFirmAccountPlanItems() {
            var privateFirmAccountPlans = this.accountsInfo.filter(a => a.companyType == 1)[0].accounts;
            return privateFirmAccountPlans.map(item => {
                return {
                    name: item.name,
                    value: item.value,
                    accruable: item.accruable,
                    assetAccount: item.assetAccount,
                    debtAccount: item.debtAccount,
                };
            });
        },
    },
};
</script>
